import { Injectable } from '@angular/core';
import { ChartType } from '../models/chart-options';

@Injectable({ providedIn: 'root' })
export class ChartTypeIconService {
  public GetIconFromChartType(chartType: ChartType): string {
    switch (chartType) {
      case ChartType.HorizontalBarChart:
        return 'bar_chart';

      case ChartType.AdvancedPieChart:
        return 'pie_chart';
    }
    return 'report';
  }
}
