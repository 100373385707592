import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageContainerComponent } from './Components/page-container/page-container.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ErrorPageComponent } from './error-page/error-page.component';
import { UrlInterceptor } from './http/url-interceptor';
import { TokenInterceptor } from '@odin/odin-authentication';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';

import {
    PaginationInterceptor,
    TransactionsFacade,
    SettlementsFacade,
    TransactionsLayoutModule
} from '@odin/odin-transactions';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardComponent } from './dashboard-page/dashboard.component';
import { MerchantService, OdinCoreModule } from '@odin/odin-core';
import { MerchantUpdateService } from '@odin/odin-transactions';
import { TerminalsPageComponent } from './terminals-page/terminals-page.component';
import { TerminalCardComponent } from './Components/terminal/terminal-card.component';
import { TerminalDetailPageComponent } from './terminal-detail-page/terminal-detail-page.component';
import { VirtualTerminalPageComponent } from './virtual-terminal-page/virtual-terminal-page.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderInterceptor } from './http/header-interceptor';
import { MatMenuModule } from '@angular/material/menu';
import { MerchantPickerComponent } from './merchant-picker/merchant-picker.component';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';

//import * as Sentry from '@sentry/angular-ivy';
import { PeblPageComponent } from './pebl-page/pebl-page.component';
import { PeblConfirmationDialogComponent } from './pebl-confirmation-dialog/pebl-confirmation-dialog.component';
import { TransactionOutcomePageComponent } from './transaction-outcome-page/transaction-outcome-page.component';
import { DashboardMetricsComponent } from './dashboard-metrics/dashboard-metrics.component';

@NgModule({
    declarations: [
        AppComponent,
        PageContainerComponent,
        ErrorPageComponent,
        DashboardComponent,
        TerminalsPageComponent,
        TerminalCardComponent,
        TerminalDetailPageComponent,
        VirtualTerminalPageComponent,
        MerchantPickerComponent,
        PeblPageComponent,
        PeblConfirmationDialogComponent,
        TransactionOutcomePageComponent,
        DashboardMetricsComponent
    ],
    bootstrap: [AppComponent],
    exports: [
        PageContainerComponent,
        ErrorPageComponent,
        DashboardComponent,
        TerminalsPageComponent,
        TerminalCardComponent,
        TerminalDetailPageComponent,
        VirtualTerminalPageComponent,
        MerchantPickerComponent,
        PeblPageComponent,
        PeblConfirmationDialogComponent,
        TransactionOutcomePageComponent,
    ], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatButtonModule,
        FontAwesomeModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        AppRoutingModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatCardModule,
        MatAutocompleteModule,
        MatInputModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !environment.production,
            connectInZone: true
        }),
        OdinCoreModule,
        TransactionsLayoutModule], providers: [
            {
                provide: ErrorHandler,
                // useValue: Sentry.createErrorHandler({
                //   showDialog: true,
                // }),
            },
            // {
            //   provide: Sentry.TraceService,
            //   deps: [Router],
            // },
            {
                provide: APP_INITIALIZER,
                useFactory: () => () => { },
                //deps: [Sentry.TraceService],
                multi: true,
            },
            TransactionsFacade,
            SettlementsFacade,
            MerchantService,
            MerchantUpdateService,
            {
                provide: 'userPoolId',
                useValue: environment.userPoolId,
            },
            {
                provide: 'clientId',
                useValue: environment.appClientId,
            },
            {
                provide: 'hostedLoginUrl',
                useValue: environment.hostedLoginUrl,
            },
            {
                provide: 'authCallbackUrl',
                useValue: environment.authCallbackUrl,
            },
            {
                provide: 'congitoResource',
                useValue: environment.congitoResource,
            },
            {
                provide: 'cognitoAudience',
                useValue: environment.cognitoAudience,
            },
            {
                provide: 'clientRoot',
                useValue: environment.clientRoot,
            },
            {
                provide: 'apiRoot',
                useValue: environment.apiRoot,
            },
            {
                provide: 'sessionInactivityLimit',
                useValue: environment.sessionInactivityLimit,
            },
            {
                provide: 'fuzzySearch',
                useValue: environment.featureToggles.fuzzySearch,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: UrlInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: HeaderInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: TokenInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: PaginationInterceptor,
                multi: true,
            },
            provideHttpClient(withInterceptorsFromDi()),
        ]
})
export class AppModule { }
