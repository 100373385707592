import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ChartBuildData,
  MerchantChangeService,
} from '@odin/odin-core';
import {
  ITransaction,
} from 'libs/odin-transactions/src/lib/transactions-layout/models';
import { TransactionsService } from 'libs/odin-transactions/src/lib/transactions-layout/services';
import { Subscription } from 'rxjs';

export enum MetricType {
  TRANSACTION_COUNT = "TransactionCount",
  TRANSACTION_VALUE = 'TransactionValue',
  REFUND_COUNT = 'RefundCount',
  REFUND_VALUE = 'RefundValue'
}

export class DashboardMetrics {
  public merchant: string;
  public message: string;
  public metrics: DashboardMetric[];
}

export class DashboardMetric {
  constructor(
    public type: number,
    public metricName: MetricType,
    public value: number,
  ) { }
}

export class MetricInterval {
  constructor(
    public name: string,
    public value: number,
  ) { }
}

@Component({
  selector: 'odin-dashboard-metrics',
  templateUrl: './dashboard-metrics.component.html',
  styleUrl: './dashboard-metrics.component.scss',
})
export class DashboardMetricsComponent implements OnInit, OnDestroy {
  private transactions: ITransaction[] = [];
  public metrics: DashboardMetrics | undefined;
  private merchantChangeEvent: Subscription;
  public loading: boolean = true;
  public error: boolean = false;
  public chartDataBreakdown: ChartBuildData;
  public intervals: MetricInterval[] = [
    new MetricInterval('Today', 0),
    new MetricInterval('Last 7 Days', 7),
    new MetricInterval('Last 30 Days', 30),
    new MetricInterval('Last 12 Months', 365)
  ];
  public interval: MetricInterval = new MetricInterval('Today', 0);

  constructor(
    private transactionService: TransactionsService,
    private merchantChangeService: MerchantChangeService,
  ) { }

  ngOnInit(): void {
    this.merchantChangeEvent =
      this.merchantChangeService.merchantChangeEvent.subscribe(() => {
        this.GetMetrics();
      });

    this.GetMetrics();
  }

  ngOnDestroy(): void {
    if (this.merchantChangeEvent !== undefined)
      this.merchantChangeEvent.unsubscribe();
  }

  public Metric(name: string): DashboardMetric {
    if (this.metrics === undefined) return new DashboardMetric(0, MetricType.TRANSACTION_COUNT, 0);
    const existing = this.metrics.metrics.find((m) => m.metricName === name);
    if (existing) return existing;
    return new DashboardMetric(0, MetricType.TRANSACTION_COUNT, 0);
  }

  public ChangeInterval(interval: number): void {
    if (interval === this.interval.value) return; // no change
    const newInterval = this.intervals.find((i) => i.value === interval);
    if (newInterval === undefined) return;
    this.interval = newInterval;
    this.GetMetrics();
  }

  private GetMetrics(): void {
    this.metrics = undefined;
    this.GetRangeTransactions(this.interval.value, () => {

      // this.chartDataBreakdown = new ChartBuilder(
      //   `${this.interval.name}'s Rundown`,
      // )
      //   .SetChartType(ChartType.LineChart)
      //   .SetAxisLabels('Time', '£ Total Sales')
      //   .SetShowLegend(false)
      //   .SetChartSize(ChartSize.Full)
      //   .SetChartHeight(270)
      //   .SetData(new ChartData(this.GetChartData(completedT)))
      //   .Build();

      this.loading = false;
    });
  }

  // private GetChartData(_t: ITransaction[]): MonekChartData {
  //   if (this.interval.value === 0) {
  //   } else if (this.interval.value === 7) {
  //   } else if (this.interval.value === 30) {
  //   } else if (this.interval.value === 365) {
  //   }
  //   const chartData = new MonekChartData().AddNode('Sales', []);
  //   return chartData;
  // }

  private GetDateStart(range: number): Date {
    const startOfToday = new Date();
    startOfToday.setDate(startOfToday.getDate() - range);
    startOfToday.setHours(0);
    startOfToday.setMinutes(0);
    startOfToday.setSeconds(0);
    startOfToday.setMilliseconds(0);
    return startOfToday;
  }

  private GetRangeTransactions(range: number, callback: () => void): void {
    this.loading = true;
    this.error = false;

    this.transactionService.getMetrics(range).subscribe(
      (metrics: DashboardMetrics) => {
        this.metrics = metrics;
        callback();
      },
      () => {
        this.error = true;
        this.loading = false;
      },
    );
  }
}
