<form
  (keydown.enter)="$event.preventDefault()"
  class="field-container d-block w-100"
>
  <div class="w-100 d-block">
    <div
      class="d-flex align-items-center justify-between bg-light rounded-3 gap-2 px-2"
    >
      <mat-icon matPrefix color="primary">search</mat-icon>
      <mat-form-field
        color="primary"
        class="monek-input flush w-100 mb-0 pb-0 flex-fill bg-light"
        appearance="fill"
      >
        <input
          [formControl]="searchControl"
          type="text"
          matInput
          maxlength="30"
          placeholder="{{ inputPlaceholder }}"
          data-test="search-input"
          (keyup)="checkSearchInputValue()"
        />
      </mat-form-field>
      <mat-icon
        color="warn"
        class="clear-search-input"
        matSuffix
        *ngIf="displayClearBtn"
        data-test="search-clear-btn"
        (click)="clearInput()"
        >close</mat-icon
      >
    </div>
  </div>
</form>
