import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, of, concat, fromEvent, Subject } from 'rxjs';
import {
  ITransaction,
  ScrollDirection,
  TransactionChannel,
  TransactionFiltersInitial,
  TransactionFiltersParams,
  TransactionStatus,
} from '../models';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { MatSidenav } from '@angular/material/sidenav';
import { fadeAnimation } from '../animations';
import { map } from 'rxjs/operators';
import * as GLOBALS from '../transactions-global.const.values';

import { ReportsDialogComponentModel } from '../components/csv-reports-dialog/reports-dialog-component-model';
import { MatDialog } from '@angular/material/dialog';
import { CsvReportsDialogComponent } from '../components/csv-reports-dialog/csv-reports-dialog.component';
import { TransactionsFacade } from '../../+state/transactions/transactions.facade';
import { MatchMediaService } from 'libs/odin-core/src/lib/services/match-media.service';

const loaded$ = new Subject<void>();

const SEARCH_PLACEHOLDER_DESKTOP = 'Search by name, amount or last 4 digits';
const SEARCH_PLACEHOLDER_MOBILE = 'Search by name, amount, digits...';

@Component({
  selector: 'odin-transactions-list-page',
  templateUrl: './transactions-list-page.component.html',
  styleUrls: ['./transactions-list-page.component.scss'],
  animations: [fadeAnimation],
  // providers: [
  //   {
  //     provide: LOADED,
  //     useValue: loaded$.asObservable(),
  //   },
  // ],
})
export class TransactionsListPageComponent implements OnInit, AfterViewInit {
  transactions$!: Observable<ITransaction[]>;
  error$!: Observable<string>;
  allowSelection$!: Observable<boolean>;
  transactionFilters$!: Observable<TransactionFiltersInitial>;
  transactionChannels$!: Observable<TransactionChannel[]>;
  transactionStatuses$!: Observable<TransactionStatus[]>;
  params$!: Observable<TransactionFiltersParams>;
  loading$!: Observable<boolean>;
  hasNext$!: Observable<boolean>;
  hasPrev$!: Observable<boolean>;
  noMoreToLoad$!: Observable<boolean>;
  scroll$!: Observable<boolean>;
  public refreshEvent: EventEmitter<void> = new EventEmitter<void>();

  searchInputValue$: Observable<string> = of('');
  isDesktopLayout = true;
  tabletQuery!: MediaQueryList;
  mobileQuery!: MediaQueryList;
  isTouch = false;
  filterIcon: IconDefinition = faFilter;
  searchControlPlaceholder = SEARCH_PLACEHOLDER_DESKTOP;
  colour = 'primary';

  @ViewChild('drawer') filters_sidenav!: MatSidenav;

  @HostListener('window:resize', ['$event'])
  detectScreenSize() {
    this.searchControlPlaceholder = this.mobileQuery.matches
      ? SEARCH_PLACEHOLDER_MOBILE
      : SEARCH_PLACEHOLDER_DESKTOP;
  }

  constructor(
    @Inject('fuzzySearch') private fuzzySearch: boolean,
    private facade: TransactionsFacade,
    public dialog: MatDialog,
    private mediaService: MatchMediaService,
  ) {
    this.isTouch = this.mediaService.isTouch();
    this.tabletQuery = this.mediaService.matchMedia(GLOBALS.MAX_SCREEN_WIDTH);
    this.mobileQuery = this.mediaService.matchMedia(GLOBALS.MIN_SCREEN_WIDTH);

    this.refreshEvent.subscribe(() => {
      this.refreshTransactions();
    })
  }

  ngAfterViewInit(): void {
    const content = document.querySelector(GLOBALS.SCROLLED_CONTAINER);

    if (content) {
      this.scroll$ = fromEvent(content, 'scroll').pipe(
        map(() => content.scrollTop > GLOBALS.SCROLLED_TOP),
      );
    }
  }

  public fuzzySearchEnabled(): boolean {
    return this.fuzzySearch;
  }

  ngOnInit(): void {
    this.facade.getTransactions();

    this.transactions$ = this.facade.loadTransactions$;

    this.loading$ = this.facade.getTransactionsLoader$;

    // get allow selection
    this.allowSelection$ = this.facade.getAllowSelection$;

    // get all params
    this.params$ = this.facade.getParams$;

    // get transactions filters from params
    this.getFiltersFromParams();

    // get search input from params
    this.getSearchValueFromParams();

    // catch error if any
    this.error$ = this.facade.getError$;

    // get pagination values
    this.hasNext$ = this.facade.getHasNext$;
    this.hasPrev$ = this.facade.getHasPrev$;
    this.noMoreToLoad$ = this.facade.noMoreToLoad$;

    // set placeholder for the search-bar
    this.searchControlPlaceholder = this.mobileQuery.matches
      ? SEARCH_PLACEHOLDER_MOBILE
      : SEARCH_PLACEHOLDER_DESKTOP;
  }

  // refresh transactions
  refreshTransactions() {
    this.facade.getTransactions();

    // complete the swipe down loader
    loaded$.next();
  }

  filterTransactions(filters: TransactionFiltersInitial) {
    // if new empty obj is dispatched search gets empty to leave it ????
    const filtersToBeSaved =
      filters === undefined ? new TransactionFiltersInitial() : filters;

    if (this.tabletQuery.matches) {
      this.filters_sidenav.close();
    }

    this.facade.saveFilters(filtersToBeSaved);

    this.facade.getTransactions();
  }

  searchTransactions(searchValue: string) {
    this.facade.saveSearchValue(searchValue);

    this.facade.getTransactions();
  }

  getFiltersFromParams() {
    this.transactionFilters$ = this.params$.pipe(
      map((params) => params.filters),
    );
  }

  getSearchValueFromParams() {
    this.searchInputValue$ = this.params$.pipe(
      map((params) => params.query || ''),
    );
  }

  loadTransactionsOnScroll(direction: ScrollDirection) {
    if (direction === ScrollDirection.Down) {
      this.loadMoreTransactions();
    }
  }

  public loadMoreTransactions(): void {
    // eslint-disable-next-line no-var
    var sub = this.loading$.subscribe((_: boolean) => {
      if (sub) sub.unsubscribe();
      if (!sub && !_) {
        // eslint-disable-next-line no-var
        var _sub = this.noMoreToLoad$.subscribe((nomoreToLoad: boolean) => {
          if (_sub) _sub.unsubscribe();
          if (nomoreToLoad) return;
          this.facade.loadMoreTransactions();
          const moreTransactions$ = this.facade.loadMoreTransactions$;
          this.transactions$ = concat(this.transactions$, moreTransactions$);
        });
      }
    });
  }

  csvReportsDownload(data: ReportsDialogComponentModel) {
    const dialogRef = this.dialog.open(CsvReportsDialogComponent, {
      width: '600px',
      position: { top: '120px' },
      data: data,
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      // Remove error from store if any
      this.facade.clearCsvReportError();

      if (this.tabletQuery.matches) {
        this.filters_sidenav.close();
      }

      // eslint-disable-next-line no-empty
      if (result === 'download') {
      }
    });
  }
}
