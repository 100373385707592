<div class="p-3 pb-0">
  <mat-card appearance="outlined" class="main-panel p-2">
    <mat-card-title>Transaction Metrics</mat-card-title>
    <mat-card-subtitle>Quick information about transactions for your account</mat-card-subtitle>
    <mat-card-content class="mt-3 px-0">
      <div class="container-fluid" *ngIf="!loading && error">
        <p class="text-center">Failed to fetch transaction metrics</p>
      </div>
      <div class="container-fluid" *ngIf="!error">
        <div class="row px-0">
          <!-- daily transaction count -->
          <div class="col-12 col-md-6 col-lg-3 py-2">
            <div class="px-2 py-3 border rounded-lg d-flex flex-column align-items-center justify-content-center h-100">
              <mat-icon class="fs-1 mb-2 outline">trending_up</mat-icon>
              <div>
                <h2 class="my-0 text-center" *ngIf="!loading; else loadingSpinner">
                  {{ Metric('TransactionCount').value }}
                </h2>
                <p class="text-center mb-0" style="min-height: 40px">
                  Transactions<br class="d-block d-lg-none" />
                  Completed
                </p>
              </div>
            </div>
          </div>
          <!-- daily transaction value -->
          <div class="col-12 col-md-6 col-lg-3 py-2">
            <div class="px-2 py-3 border rounded-lg d-flex flex-column align-items-center justify-content-center h-100">
              <mat-icon class="fs-1 mb-2 outline">savings</mat-icon>
              <div>
                <h2 class="my-0 text-center" *ngIf="!loading; else loadingSpinner">
                  {{ Metric('TransactionValue').value | currency: 'GBP' }}
                </h2>
                <p class="text-center mb-0" style="min-height: 40px">
                  Total Transactions<br class="d-block d-lg-none" />
                  Value
                </p>
              </div>
            </div>
          </div>
          <!-- daily refund count -->
          <div class="col-12 col-md-6 col-lg-3 py-2">
            <div class="px-2 py-3 border rounded-lg d-flex flex-column align-items-center justify-content-center h-100">
              <mat-icon class="fs-1 mb-2 outline">restore</mat-icon>
              <div>
                <h2 class="my-0 text-center" *ngIf="!loading; else loadingSpinner">
                  {{ Metric('RefundCount').value }}
                </h2>
                <p class="text-center mb-0" style="min-height: 40px">
                  Refunds<br class="d-block d-lg-none" />
                  Completed
                </p>
              </div>
            </div>
          </div>
          <!-- daily refund value -->
          <div class="col-12 col-md-6 col-lg-3 py-2">
            <div class="px-2 py-3 border rounded-lg d-flex flex-column align-items-center justify-content-center h-100">
              <mat-icon class="fs-1 mb-2 outline">block</mat-icon>
              <div>
                <h2 class="my-0 text-center" *ngIf="!loading; else loadingSpinner">
                  {{ Metric('RefundValue').value | currency: 'GBP' }}
                </h2>
                <p class="text-center mb-0" style="min-height: 40px">
                  Total Refunds<br class="d-block d-lg-none" />
                  Value
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="false">
        <odin-chart class="w-100" [showTitle]="false" [chartData]="chartDataBreakdown"></odin-chart>
      </div>

      <div class="d-flex align-items-stretch justify-content-start mt-3 gap-3 px-2">
        <ng-container *ngFor="let i of intervals">
          <div (click)="ChangeInterval(i.value)"
            class="pointer p-2 rounded-3 border text-center d-flex align-items-center justify-content-center interactable" [ngClass]="{
              'mon-border-success': interval.value === i.value,
              'mon-bg-success': interval.value === i.value,
              'mon-text-success': interval.value === i.value,
              'mon-bg-transparent': interval.value !== i.value,
              'text-dark': interval.value !== i.value
            }">
            <span>{{ i.name }}</span>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</div>


<ng-template #loadingSpinner>
  <odin-loading-spinner [active]="true"></odin-loading-spinner>
</ng-template>