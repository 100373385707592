<!-- <div class="page-container max-width">
  <odin-dashboard-panel [panelTitle]="'Overview'">
    <odin-chart class="mobile-full-width" style="width: 75%;" [showTitle]="true" [chartData]="todayChartData"></odin-chart>  
    <odin-cash-calendar class="mobile-full-width" style="width: 25%;" [showTitle]="true" ></odin-cash-calendar>
  </odin-dashboard-panel>
  <odin-dashboard-panel [panelTitle]="'Reports Summary'">
    <odin-chart class="mobile-full-width" style="width: 50%;" [showTitle]="true" [chartData]="grossChartData"></odin-chart>

    <div style="max-width: 50%;" class="mobile-full-width">
      <div style="width: 100%;">
        <h2 style="margin: 0;">Alerts and notifications</h2>
        <span style="opacity: 0.7;">Notifications will disappear after 24hrs</span>
        <div style="margin: 5px auto; border: 1px solid #bbb; padding: 5px; border-radius: 6px;">
          <h4 style="margin: 0; display: flex; vertical-align: middle;"><mat-icon style="color: red; margin-right: 3px;">error</mat-icon>1 Transaction Refunded</h4>
          <div>
            <span>One transaction has been refunded today 
              <a style="cursor: pointer; text-decoration: underline;">CLICK HERE</a> 
              for more details</span>
          </div>
        </div>

        <div style="margin: 5px auto; border: 1px solid #bbb; padding: 5px; border-radius: 6px;">
          <h4 style="margin: 0; display: flex; vertical-align: middle;"><mat-icon style="color: green; margin-right: 3px;">notifications</mat-icon>Notification</h4>
          <div>
            <span>Last week you were up by 9% in sales. Keep It Up!</span>
          </div>
        </div>
      </div>
    </div>
  </odin-dashboard-panel> 
</div> -->

<div
  class="overflow-auto d-flex align-items-stretch justify-content-between flex-column h-100"
>
  <odin-dashboard-metrics *ngIf="LoadDashboard()"></odin-dashboard-metrics>
  <odin-announcement-display
    [messages]="messages"
    data-test="dashboard-announcements"
  ></odin-announcement-display>
  <div class="flex-fill"></div>
  <div class="align-self-end w-100" *ngIf="pciEnabled()">
    <odin-pci-widget></odin-pci-widget>
  </div>
</div>
