import { Component, Input } from '@angular/core';
import { ChartBuildData } from '../../models/chart-build-data';
import { ChartType } from '../../models/chart-options';
import { ChartTypeIconService } from '../../services/chart-icon-service';

export class ChartTypeOption {
  constructor(
    public chartType: ChartType,
    public icon: string,
  ) {}
}

@Component({
  selector: 'odin-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent {
  @Input()
  public showTitle = true;
  @Input()
  public chartData?: ChartBuildData;
  public chartType = ChartType;

  get chartOptions(): ChartTypeOption[] {
    if (!this.chartData) return [];
    const options = this.chartData.chartTypeOptions;
    const chartTypOptionList: ChartTypeOption[] = [];
    options.forEach((val: ChartType) => {
      chartTypOptionList.push(
        new ChartTypeOption(
          val,
          this.chartTypeIconService.GetIconFromChartType(val),
        ),
      );
    });
    return chartTypOptionList;
  }

  constructor(private chartTypeIconService: ChartTypeIconService) {}

  public renderChart(
    _chartData: ChartBuildData,
    _chartType: ChartType,
  ): boolean {
    if (!_chartData) return false;
    if (_chartData.chartType == _chartType) return true;
    return false;
  }

  public ChangeChartType(chartTypeOption: ChartTypeOption): void {
    if (!this.chartData) return;
    this.chartData.chartType = chartTypeOption.chartType;
  }
}
