export const environment = {
  name: 'staging',
  production: false,
  protocol: 'https://',
  baseAddress: 'api.staging.monek.com',
  odinBaseAddress: 'api.staging.monek.com/odin',
  clientRoot: 'https://staging-portal.monek.com/',
  apiRoot: 'api.staging.monek.com/odin/',
  releaseVersion: 'SHA-d4a20cb076117ed38f57c1fa6766c485d0583422',

  // cognito
  userPoolId: 'eu-west-2_FBa9FdzbI',
  appClientId: '49icjbbdjfum6lm89htiiec5ql',
  hostedLoginUrl: 'https://staging-authorise.monek.com',
  authCallbackUrl:
    'https://staging-portal.monek.com/authentication/auth-callback',

  congitoResource: 'https://staging-portal.monek.com/dashboard',
  cognitoAudience: 'https://api.staging.monek.com/odin/transactions',
  sessionInactivityLimit: 900000,

  sentryKey:
    'https://77cf1ecf142e4954b99f121e84bca18d@o4505487026159616.ingest.sentry.io/4505487030157312',
  sentrySampleRate: 1.0,
  sentryMaskData: true,

  featureToggles: {
    'fuzzySearch': true,
    'txn-recharge': true,
    'vt-page': true,
    'pebl-page': true,
    'pci-widget': false,
    'dashboard-metrics': false
  },
};
