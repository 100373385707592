import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  IFormattedTransaction,
  ITransaction,
  TransactionChannel,
  TransactionStatus,
  TransactionType,
} from '../../models';
import { TransactionRefund } from '../../models/transaction-refund';
import {
  RefundModalData,
  RefundTransactionModalComponent,
} from '../refund-transaction-modal/refund-transaction-modal.component';
import { AccessGuardService, UserAccessLevel } from '@odin/odin-authentication';
import { TransactionsService } from '../../services/transactions.service';
import { TransactionFormatterService } from '../../services/transaction-formatter.service';
import { NotificationService } from 'libs/odin-core/src/lib/services/notification.service';
import {
  RechargeModalData,
  RechargeTransactionModalComponent,
} from '../recharge-transaction-modal/recharge-transaction-modal.component';
import { TransactionRecharge } from '../../models/transaction-recharge';
import {
  ReverseModalData,
  ReverseTransactionModalComponent,
} from '../reverse-transaction-modal/reverse-transaction-modal.component';
import { ReceiptDetail } from '../../../print-receipt/print-receipt.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHeadset,
  faLink,
  faGlobe,
  faRotateLeft,
  faRepeat,
  faStore,
  faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { StaticHelpers } from '@odin/odin-core';

export class TransactionDetailModalData {
  constructor(
    public transaction: ITransaction,
    public exclude: string[],
  ) {}
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _audit_logs: any[];
  }
}

@Component({
  selector: 'odin-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionDetailsComponent implements OnInit {
  public formattedTransaction: WritableSignal<IFormattedTransaction> =
    signal<IFormattedTransaction>({} as IFormattedTransaction);
  public relatedTransactions: WritableSignal<IFormattedTransaction[]> = signal<
    IFormattedTransaction[]
  >([]);
  public relatedLoading: WritableSignal<boolean> = signal<boolean>(false);
  public shelper = StaticHelpers;

  transactionType = 'Transaction';
  formattedChannel = '';
  formattedType = '';
  badgeClass = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public modalData: {
      data: TransactionDetailModalData;
      refreshTrigger: EventEmitter<void>;
    },
    private dialog: MatDialog,

    private transactionService: TransactionsService,
    private transactionFormatterService: TransactionFormatterService,
    public accessService: AccessGuardService,
    private notificationService: NotificationService,
  ) {}

  public showRefundAmount(): boolean {
    if (this.formattedTransaction().amountRefunded !== undefined) return false;
    const amountRefunded = this.formattedTransaction().amountRefunded;
    if (amountRefunded === undefined) return false;
    if (amountRefunded <= 0) return false;
    return true;
  }

  public showPaymentDescription(): boolean {
    if (this.formattedTransaction().paymentDetail === undefined) return false;
    if (this.formattedTransaction().paymentDetail === null) return false;
    if (this.formattedTransaction().paymentDetail.length === 0) return false;
    return true;
  }

  public canFetchRelation(): boolean {
    return this.accessService.isMinLevel(UserAccessLevel.Admin);
  }

  public canShowRelation(crossRef: string): boolean {
    return this.modalData.data.exclude.indexOf(crossRef) === -1;
  }

  public refreshTransaction(): void {
    this.transactionService
      .getTransaction(this.formattedTransaction().crossReference)
      .subscribe((transaction) => {
        if (transaction === null) return;
        this.modalData.data.transaction = transaction;
        this.format();
        this.modalData.refreshTrigger.emit();
      });
  }

  ngOnInit(): void {
    this.format();
  }

  public format(): void {
    this.formattedTransaction.set(
      this.transactionFormatterService.formatTransaction(
        this.modalData.data.transaction,
      ),
    );
    this.formattedTransaction.update((t: IFormattedTransaction) => {
      t.remainingValue = t.amount;
      return t;
    });

    if (this.canFetchRelation()) {
      this.relatedLoading.set(true);
      this.transactionService
        .GetRelatedTransactions(this.formattedTransaction())
        .subscribe(
          (res: ITransaction[]) => {
            this.relatedTransactions.set(
              res.map((t: ITransaction) => {
                return this.transactionFormatterService.formatTransaction(t);
              }),
            );

            this.relatedTransactions().forEach((t: IFormattedTransaction) => {
              if (t.transactionType !== 'refund') return;
              if (t.status === 'declined') return;
              if (this.formattedTransaction === undefined) return;
              this.formattedTransaction.update(
                (value: IFormattedTransaction) => {
                  if (value.amountRefunded == undefined)
                    value.amountRefunded = 0;
                  if (value.remainingValue == undefined)
                    value.remainingValue = 0;

                  value.remainingValue = value.remainingValue - t.amount;
                  value.amountRefunded = value.amountRefunded + t.amount;
                  return value;
                },
              );
            });

            this.relatedLoading.set(false);
          },
          () => {
            this.relatedLoading.set(false);
            this.notificationService.SmallDialog(
              'Failed to fetch related transactions.',
            );
          },
        );
    }

    this.transactionType =
      this.formattedTransaction().transactionType === 'refund'
        ? 'Refund'
        : 'Transaction';

    this.badgeClass = this.transactionFormatterService.getTransactionBadgeClass(
      this.formattedTransaction().status,
    );

    this.formattedChannel = this.transactionFormatterService.getFormattedValue(
      this.formattedTransaction().channel.toString(),
    );
    this.formattedType = this.transactionFormatterService.getFormattedValue(
      this.formattedTransaction().transactionType.toString(),
    );
  }

  // MOVE
  public startRefundRequest(): void {
    const refundRequestModal = this.dialog.open(
      RefundTransactionModalComponent,
      {
        data: new RefundModalData(this.formattedTransaction()),
        autoFocus: false,
        panelClass: 'main-panel',
        maxWidth: 400,
      },
    );
    refundRequestModal.afterClosed().subscribe((refund: TransactionRefund) => {
      if (refund == null) return;
      this.refreshTransaction();
    });
  }

  public startRechargeRequest(): void {
    const rechargeRequestModal = this.dialog.open(
      RechargeTransactionModalComponent,
      {
        data: new RechargeModalData(this.formattedTransaction()),
        autoFocus: false,
        panelClass: 'main-panel',
        maxWidth: 400,
      },
    );
    rechargeRequestModal
      .afterClosed()
      .subscribe((recharge: TransactionRecharge) => {
        if (recharge == null) return;
        this.refreshTransaction();
      });
  }

  public startReverseRequest(): void {
    const reverseRequestModal = this.dialog.open(
      ReverseTransactionModalComponent,
      {
        data: new ReverseModalData(this.formattedTransaction()),
        autoFocus: false,
        panelClass: 'main-panel',
        maxWidth: 400,
      },
    );
    reverseRequestModal.afterClosed().subscribe(() => {
      this.refreshTransaction();
    });
  }

  public createReceipt(): ReceiptDetail {
    return new ReceiptDetail(
      '',
      this.formattedTransaction().crossReference,
      this.formattedTransaction().cardHolderDetails.name ||
        this.formattedTransaction().maskedCard,
      this.formattedTransaction().paymentReference,
      this.formattedTransaction().createdOn,
      '',
      this.formattedTransaction().amount,
      this.formattedTransaction().currencyCountry,
    );
  }

  public printReceipt(): void {
    window.print();
  }

  public getLatestCancelationDate(): Date {
    const date = new Date(this.formattedTransaction().createdOn).setHours(
      23,
      59,
    );
    return new Date(date);
  }

  public hasCancelationPassed(): boolean {
    return this.getLatestCancelationDate() < new Date();
  }

  public canPrint(): boolean {
    if (this.formattedTransaction().status === TransactionStatus.Cancelled)
      return false;
    return true;
  }

  public isTransactDay(): boolean {
    // check happened TODAY before 23:59pm
    const transactionTime = new Date(this.formattedTransaction().createdOn);
    const thisMorning = new Date(Date.now());
    thisMorning.setHours(0);
    thisMorning.setMinutes(0);
    thisMorning.setSeconds(0);
    thisMorning.setMilliseconds(0);
    const tomorrow = new Date(thisMorning);
    tomorrow.setHours(23);
    tomorrow.setMinutes(59);
    tomorrow.setSeconds(59);
    if (transactionTime > thisMorning && transactionTime < tomorrow)
      return false;
    return true;
  }

  public canReverse(): boolean {
    if (!this.accessService.hasGrant('txn:Reverse'))
      return this.LogAndReturn('No txn:Reverse Grant', false);
    if (this.formattedTransaction().transactionType === 'refund')
      return this.LogAndReturn('Is Refund', false);
    if (this.formattedTransaction().isReversed)
      return this.LogAndReturn('Is Reversed', false);
    if (this.formattedTransaction().isReversal)
      return this.LogAndReturn('Is Reversal', false);
    if (this.formattedTransaction().status === TransactionStatus.Cancelled)
      return this.LogAndReturn('Is Cancelled', false);
    if (this.isTransactDay())
      return this.LogAndReturn('Is Transact Day', false);
    if (this.relatedLoading()) return false;
    return true;
  }

  public canRefund(): boolean {
    if (!this.accessService.hasGrant('txn:Refund'))
      return this.LogAndReturn('No txn:Refund Grant', false);
    if (this.formattedTransaction().transactionType === 'refund')
      return this.LogAndReturn('Is Refund', false);
    if (this.formattedTransaction().isReversed)
      return this.LogAndReturn('Is Reversed', false);
    if (this.formattedTransaction().isReversal)
      return this.LogAndReturn('Is Reversal', false);
    if (this.formattedTransaction().status === TransactionStatus.Cancelled)
      return this.LogAndReturn('Is Cancelled', false);
    if (this.relatedLoading()) return false;
    return true;
  }

  public canReCharge(): boolean {
    if (!this.accessService.hasGrant('txn:Recharge'))
      return this.LogAndReturn('No txn:Recharge Grant', false);
    if (this.formattedTransaction().transactionType === 'refund')
      return this.LogAndReturn('Is Refund', false);
    if (this.formattedTransaction().isReversed)
      return this.LogAndReturn('Is Reversed', false);
    if (this.formattedTransaction().isReversal)
      return this.LogAndReturn('Is Reversal', false);
    if (this.formattedTransaction().status === TransactionStatus.Cancelled)
      return this.LogAndReturn('Is Cancelled', false);
    if (!this.isTransactDay())
      return this.LogAndReturn('Is Transact Day', false);
    if (this.relatedLoading()) return false;
    return true;
  }

  public getStackTrace(): string {
    try {
      throw new Error('');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.stack || '';
    }
  }

  public LogAndReturn(
    msg: string,
    toReturn: boolean,
    logLevel: number = 0,
  ): boolean {
    if (window._audit_logs == undefined) window._audit_logs = [];
    window._audit_logs.push({
      level: logLevel,
      log: msg,
      stack: this.getStackTrace(),
    });
    return toReturn;
  }

  transactionIcon(transaction: ITransaction): IconProp {
    if (transaction.transactionType == 'virtual_terminal') return faHeadset;
    if (transaction.transactionType == 'pebl') return faLink;
    if (transaction.transactionType == 'ecommerce') return faGlobe;
    if (transaction.transactionType == 'refund') return faRotateLeft;
    if (transaction.channel == 'recurring') return faRepeat;
    if (transaction.channel == 'ecommerce') return faGlobe;
    if (transaction.channel == 'moto') return faHeadset;
    if (transaction.channel == 'cardholder_present') return faStore;
    return faCircleQuestion;
  }

  public ViewRelatedTransaction(transaction: ITransaction): void {
    this.dialog.open(TransactionDetailsComponent, {
      data: {
        data: new TransactionDetailModalData(transaction, [
          ...this.modalData.data.exclude,
          transaction.crossReference,
        ]),
        refreshTrigger: this.modalData.refreshTrigger,
      },
      panelClass: ['main-panel', 'large-modal'],
    });
  }
}
