<div
  class="root-container"
  *ngIf="chartData"
  [ngStyle]="{ width: chartData.size }"
>
  <h2 *ngIf="chartData.name && showTitle" style="text-align: left;">{{ chartData.name }}</h2>
  <div #ContainerRef class="chart-container">
    <ngx-charts-bar-vertical
      *ngIf="renderChart(chartData, chartType.VerticalBarChart)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [xAxis]="true"
      [yAxis]="true"
      [xAxisLabel]="chartData.xAxisLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [roundDomains]="true"
      [scheme]="chartData.colourScheme"
      [legend]="chartData.showLegend"
      [legendPosition]="chartData.legendPosition"
    >
    </ngx-charts-bar-vertical>

    <ngx-charts-bar-horizontal
      *ngIf="renderChart(chartData, chartType.HorizontalBarChart)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [xAxis]="true"
      [yAxis]="true"
      [xAxisLabel]="chartData.xAxisLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [roundDomains]="true"
      [scheme]="chartData.colourScheme"
      [legend]="chartData.showLegend"
      [legendPosition]="chartData.legendPosition"
    >
    </ngx-charts-bar-horizontal>

    <ngx-charts-bar-vertical-stacked
      *ngIf="renderChart(chartData, chartType.StackedVerticalBarChart)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [xAxis]="true"
      [yAxis]="true"
      [xAxisLabel]="chartData.xAxisLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [roundDomains]="true"
      [scheme]="chartData.colourScheme"
      [legend]="chartData.showLegend"
      [legendPosition]="chartData.legendPosition"
    >
    </ngx-charts-bar-vertical-stacked>

    <ngx-charts-bar-vertical-2d
      *ngIf="renderChart(chartData, chartType.GroupedVerticalBarChart)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [xAxis]="true"
      [yAxis]="true"
      [xAxisLabel]="chartData.xAxisLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [roundDomains]="true"
      [scheme]="chartData.colourScheme"
      [legend]="chartData.showLegend"
      [legendPosition]="chartData.legendPosition"
    >
    </ngx-charts-bar-vertical-2d>

    <ngx-charts-pie-chart
      *ngIf="renderChart(chartData, chartType.PieChart)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [labels]="true"
      [scheme]="chartData.colourScheme"
      [legend]="chartData.showLegend"
      [legendPosition]="chartData.legendPosition"
    >
    </ngx-charts-pie-chart>

    <ngx-charts-pie-grid
      *ngIf="renderChart(chartData, chartType.PieChartGrid)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [label]="chartData.yAxisLabel"
      [scheme]="chartData.colourScheme"
    >
    </ngx-charts-pie-grid>

    <ngx-charts-advanced-pie-chart
      *ngIf="renderChart(chartData, chartType.AdvancedPieChart)"
      style="line-height: 32px"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [label]="chartData.yAxisLabel"
      [scheme]="chartData.colourScheme"
    >
    </ngx-charts-advanced-pie-chart>

    <ngx-charts-line-chart
      *ngIf="renderChart(chartData, chartType.LineChart)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [xAxisLabel]="chartData.xAxisLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [roundDomains]="true"
      [legend]="chartData.showLegend"
      [legendPosition]="chartData.legendPosition"
      [scheme]="chartData.colourScheme"
    >
    </ngx-charts-line-chart>

    <ngx-charts-area-chart
      *ngIf="renderChart(chartData, chartType.AreaLineChart)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [xAxisLabel]="chartData.xAxisLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="chartData.showLegend"
      [legendPosition]="chartData.legendPosition"
      [scheme]="chartData.colourScheme"
    >
    </ngx-charts-area-chart>

    <ngx-charts-area-chart-stacked
      *ngIf="renderChart(chartData, chartType.StackedAreaChart)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [xAxisLabel]="chartData.xAxisLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="chartData.showLegend"
      [legendPosition]="chartData.legendPosition"
      [scheme]="chartData.colourScheme"
    >
    </ngx-charts-area-chart-stacked>

    <ngx-charts-area-chart-normalized
      *ngIf="renderChart(chartData, chartType.NormalizedAreaLineChart)"
      [view]="[ContainerRef.offsetWidth, chartData.height]"
      [results]="chartData.data"
      [xAxisLabel]="chartData.xAxisLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="chartData.showLegend"
      [legendPosition]="chartData.legendPosition"
      [scheme]="chartData.colourScheme"
    >
    </ngx-charts-area-chart-normalized>
  </div>
  <mat-icon *ngFor="let chartTypeOption of chartOptions" class="mat-icon-button" (click)="ChangeChartType(chartTypeOption)">{{ chartTypeOption.icon }}</mat-icon>
</div>
