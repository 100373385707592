export class StaticHelpers {
  public static uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  public static isNullOrEmpty(str: string | null | undefined | Date): boolean {
    if (str === null) return true;
    if (str === undefined) return true;
    if (str === '') return true;
    return false;
  }
}
